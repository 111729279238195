<script setup>
import { defineModel, onMounted, ref } from 'vue'
import apiClient from '@/apiClient'

const model = defineModel()
const units = ref({})

onMounted(() => {
  apiClient.get('/api/product').then(response => {
    units.value = response.data
  })
})
</script>
<template>
  <select v-model="model" class="p-2">
    <option
      v-for="unit in units"
      :key="unit"
      :value="unit.id"
      v-text="unit.name"
    />
  </select>
</template>

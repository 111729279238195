<script setup>
import apiClient from '@/apiClient.js'
import { computed, onMounted, ref } from 'vue'
import { useBreadcrumbStore } from '@/stores/BreadcrumbStore'
import { useRoute } from 'vue-router'
import InteractiveTable from '@/components/InteractiveTable'
import DateField from '@/components/DateField.vue'
import ReferenceComments from '@/components/ReferenceComments'
import ReferenceHistory from '@/components/ReferenceHistory'
import Router from '@/router/index.js'
import dayjs from 'dayjs'
import * as Icon from '@heroicons/vue/24/solid'
var advancedFormat = require('dayjs/plugin/advancedFormat')
dayjs.extend(advancedFormat)

import { v4 as uuidv4 } from 'uuid'
import SearchCustomer from '@/components/SearchCustomer.vue'

const apiUrl = process.env.VUE_APP_API_URL
const route = useRoute()
const breadcrumbStore = useBreadcrumbStore()
const invoice = ref({})
const invoiceSent = ref(false)
const documentFrame = ref(null)
const editable = ref(false)
const customerChanged = ref(false)
const emailContent = ref({
  subject: 'Your invoice is ready',
  body: 'Please find your invoice attached.\n\nThank you for your business.\nMedic 1 Team',
  recipients: new Set(),
})
const pdfTimestamp = ref(new Date().getTime())

onMounted(() => {
  getInvoice()
})

const getInvoice = () => {
  apiClient
    .get('/api/invoice/' + route.params.id)
    .then(r => {
      invoice.value = r.data
      breadcrumbStore.setValue(invoice.value?.reference?.name ?? '')
    })
    .catch(e => {})
}

const edit = () => (editable.value = true)

const save = () => {
  editable.value = false
  apiClient
    .post('/api/invoice/' + invoice.value.id, {
      _method: 'PUT',
      ...invoice.value,
    })
    .then(r => {
      getInvoice()
      refreshIframe()
    })
    .catch(e => {})

  if (customerChanged.value === true) {
    apiClient
      .post('/api/reference/' + invoice.value.reference.id, {
        _method: 'PUT',
        customer_id: invoice.value.reference.customer.id,
      })
      .catch(e => {})
  }

  setTimeout(() => {
    refreshIframe()
  }, 1000)
}

const setPaid = () => {
  if (!confirm('Are you sure you want to set this invoice as paid?')) {
    return
  }
  apiClient
    .post('/api/invoice/' + invoice.value.id + '/paid', {
      _method: 'PUT',
    })
    .then(() => {
      window.triggerConfetti()
      getInvoice()
    })
    .catch(e => {})
}

const setUnpaid = () => {
  if (!confirm('Are you sure you want to set this invoice as unpaid?')) {
    return
  }
  apiClient
    .post('/api/invoice/' + invoice.value.id + '/unpaid', {
      _method: 'PUT',
    })
    .then(() => {
      getInvoice()
    })
    .catch(e => {})
}

/*
    Add a blank entry form but do not save
*/
const addItem = () => {
  const today = new Date()
  invoice.value.items.push({
    date: today.toISOString().split('T')[0],
    description: '',
    subtotal: 0.0,
    vatable: 1,
    uuid: uuidv4(),
  })
}

const changeCustomer = customer => {
  invoice.value.reference.customer = customer
  customerChanged.value = true
}

/**
    Delete an invoice item
*/
const deleteItem = inItem => {
  if (inItem.id) {
    apiClient
      .delete('/api/invoice/' + invoice.value.id + '/item/' + inItem.id, {
        _method: 'DELETE',
      })
      .then(() => {
        getInvoice()
      })
      .catch(e => {})
  }
}

/**
    Save changes to an invoice item
 */
const saveItem = inItem => {
  if (inItem.id) {
    apiClient
      .post('/api/invoice/' + invoice.value.id + '/item/' + inItem.id, {
        _method: 'PUT',
        ...inItem,
      })
      .then(() => {
        getInvoice()
        refreshIframe()
      })
      .catch(e => {})
  }

  // Create new item
  else {
    inItem.invoice_id = invoice.value.id
    apiClient
      .post('/api/invoice/' + invoice.value.id + '/item', {
        ...inItem,
      })
      .then(() => {
        getInvoice()
      })
      .catch(e => {})

    setTimeout(() => {
      refreshIframe()
    }, 1000)
  }
}

/*
    Update invoice items which have been editted by TableList
*/
const updateItems = data => {
  invoice.value.items.push(data)
}

/**
  Update value of invoice flagged_at
  */
const updateFlagged = flagged_at => (invoice.value.flagged_at = flagged_at)

/**
 Toggle recipients for invoice email
 */
const toggleEmailRecipient = contact => {
  const id = contact.target.value
  if (emailContent.value.recipients.has(id)) {
    emailContent.value.recipients.delete(id)
  } else {
    emailContent.value.recipients.add(id)
  }
}

/**
Request for invoice to be sent to given recipients, subject and body
*/
const sendInvoice = () => {
  const data = {
    recipients: Array.from(emailContent.value.recipients),
    subject: emailContent.value.subject,
    body: emailContent.value.body,
  }

  if (data.recipients.length < 1) {
    return alert('Oops.. you forgot to select who to send this to!')
  }

  apiClient
    .post('/api/invoice/' + invoice.value.id + '/email', data)
    .then(r => {
      invoiceSent.value = true
    })
    .catch(e => {})
}

const iframeSrc = computed(
  () =>
    `${apiUrl}/api/invoice/${invoice.value.id}/document?${pdfTimestamp.value}`,
)

const refreshIframe = () => {
  pdfTimestamp.value = ref(new Date().getTime())
  console.log('refreshing')
}
</script>
<template>
  <div>
    <form-box :header="invoice.reference?.name">
      <template v-slot:nav>
        <template v-if="invoice.id">
          <DeleteButton
            :url="'/api/invoice/' + invoice.id"
            redirect="/invoice"
          />
          <flag-button
            :status="invoice.flagged_at"
            :url="'/api/invoice/' + invoice.id + '/flag'"
            @update:status="updateFlagged"
          ></flag-button>
          <button
            class="btn-plain bg-green-600"
            @click.prevent="setPaid"
            v-if="!invoice.paid_at"
          >
            <Icon.SparklesIcon class="icon" /> Mark Paid
          </button>
          <button
            class="btn-plain bg-red-600"
            @click.prevent="setUnpaid"
            v-else
          >
            <Icon.SparklesIcon class="icon" /> Mark Unpaid
          </button>
        </template>
      </template>
    </form-box>
    <form-box header="Details">
      <template v-slot:nav>
        <edit-button @click.prevent="edit" v-if="!editable"></edit-button>
        <save-button
          @save="save"
          @cancel="editable = false"
          title="Save"
          :hideCancel="false"
          v-if="editable"
        ></save-button>
      </template>
      <div
        class="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-6 gap-6 max-w-full"
        v-if="invoice.id"
      >
        <div>
          <label>Customer</label>
          <span
            ><router-link
              :to="'/customer/' + invoice.reference?.customer?.id"
              >{{ invoice.reference?.customer?.company }}</router-link
            ></span
          >
          <SearchCustomer
            v-if="editable"
            :customer="invoice.reference?.customer"
            :resetAfterSelection="false"
            @selected="changeCustomer"
          />
        </div>

        <div>
          <label>Reference</label>
          <ReferenceNumber :reference="invoice.reference" />
        </div>

        <div>
          <label>Purchase Order (PO)</label>
          <TextField
            :data="invoice.purchase_order"
            :editable="editable"
            @update="val => (invoice.purchase_order = val)"
          />
        </div>
        <div>
          <label>Subtotal</label>
          <span>&pound;<CurrencyOutput :price="invoice?.subtotal ?? 0" /></span>
        </div>
        <div>
          <label>VAT</label>
          <span>&pound;<CurrencyOutput :price="invoice?.vat ?? 0" /></span>
        </div>
        <div>
          <label>Total</label>
          <span
            >&pound;<CurrencyOutput :price="invoice.subtotal + invoice.vat"
          /></span>
        </div>
        <div>
          <label>Address</label>
          <select v-model="invoice.address_id" v-if="editable">
            <option value="">Default Address</option>
            <option
              v-for="address in invoice.reference.customer.address"
              :key="address"
              :value="address.id"
              :disabled="!editable"
            >
              {{ address.data }}
            </option>
          </select>
          <span v-else>{{ invoice.address_id ?? 'Default Address' }}</span>
        </div>
      </div>
    </form-box>

    <form-box header="Dates">
      <div
        class="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-7 gap-6 max-w-full"
        v-if="invoice.id"
      >
        <div>
          <label>Created</label>
          <DateField :data="invoice.created_at" />
        </div>
        <div>
          <label>Issue Date</label>
          <DateField
            :data="invoice.issue_date"
            :editable="editable"
            @update="val => (invoice.issue_date = val)"
          />
        </div>
        <div>
          <label>Last Sent</label>
          <DateField :data="invoice.sent_at" />
        </div>
        <div>
          <label>Due Date</label>
          <DateField
            :data="invoice.expected_at"
            :editable="editable"
            @update="val => (invoice.expected_at = val)"
          />
        </div>
        <div>
          <label>Payment</label>
          <DateField :data="invoice.paid_at" v-if="invoice.paid_at" /><span
            v-else
            >Unpaid</span
          >
        </div>
      </div>
    </form-box>

    <form-box header="Items" v-if="invoice.items">
      <InteractiveTable
        :data="invoice.items"
        :cols="[
          {
            name: 'Billing Date',
            row: 'date',
            editable: true,
            type: 'date',
          },
          {
            name: 'Description',
            row: 'description',
            editable: true,
          },
          {
            name: 'Subtotal',
            row: 'subtotal',
            editable: true,
            type: 'currency',
          },
          {
            name: 'VAT',
            row: 'vatable',
            editable: true,
            type: 'checkbox',
          },
        ]"
        :addable="true"
        :deletable="true"
        :editable="true"
        @delete="deleteItem"
        @save="saveItem"
      />
    </form-box>

    <form-box header="Additional Information">
      <textarea
        v-model="invoice.additionalInformation"
        class="w-full"
        :disabled="!editable"
        placeholder="Additional information can be added to the invoice by editing this."
      ></textarea>
      <small
        >This information will appear on a supplimentary page of the
        invoice.</small
      >
    </form-box>
    <form-box header="Send via Email">
      <div v-if="invoiceSent">Invoice has been successfully sent.</div>
      <div v-else>
        <div class="grid grid-cols-auto md:grid-cols-2 gap-4 mb-4">
          <div
            v-for="contact in invoice.reference?.customer?.contacts"
            :key="contact"
          >
            <label class="select-none">
              <input
                type="checkbox"
                :value="contact.id"
                @click="toggleEmailRecipient"
              />
              {{ contact.name }} &lt;{{ contact.email }}&gt;
            </label>
          </div>
        </div>
        <input
          type="text"
          class="w-full block mb-4"
          placeholder="Invoice email subject.."
          v-model="emailContent.subject"
        />
        <textarea
          class="w-full h-48 block mb-4"
          v-model="emailContent.body"
        ></textarea>
        <div class="flex justify-between header-container">
          <button class="btn" @click.prevent="sendInvoice">Send</button>
        </div>
      </div>
    </form-box>

    <div class="grid grid-cols-1 lg:grid-cols-2 gap-6 mb-6">
      <ReferenceComments
        v-if="invoice.reference"
        :reference_id="invoice.reference.id"
      />

      <ReferenceHistory
        v-if="invoice.reference"
        :reference_id="invoice.reference.id"
      />
    </div>

    <form-box header="Document">
      <template v-slot:nav>
        <DownloadButton
          :url="'/api/invoice/' + invoice.id + '/document'"
          :filename="invoice.reference?.txt + '.pdf'"
          :mime="application / pdf"
        />
      </template>
      <iframe
        reference="documentFrame"
        v-if="invoice.id"
        :src="iframeSrc"
        class="w-full"
        height="800"
      />
    </form-box>
  </div>
</template>

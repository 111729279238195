<script setup>
import { onMounted, ref } from "vue";
import apiClient from "@/apiClient.js";
import { useRoute } from "vue-router";
import { useBreadcrumbStore } from "@/stores/BreadcrumbStore";
import SearchContact from "@/components/SearchContact";
import TableList from "@/components/TableList.vue";
import dayjs from "dayjs";
var advancedFormat = require("dayjs/plugin/advancedFormat");
dayjs.extend(advancedFormat);

const route = useRoute();
const breadcrumbStore = useBreadcrumbStore();
const contactId = route.params.id;
const contact = ref({});
const tickets = ref([]);
const editable = ref(false);

onMounted(() => {
  apiClient
    .get(`/api/contact/${contactId}`)
    .then((data) => {
      contact.value = data.data;
      breadcrumbStore.setValue(contact.value.name);
    })
    .then(() => {
      apiClient.get(`/api/contact/${contactId}/tickets`).then((data) => {
        tickets.value = data.data;
      });
    });
});

const edit = () => {
  editable.value = true;
};
const save = () => {
  editable.value = false;
  apiClient.put(`/api/contact/${contact.value.id}`, {
    ...contact.value,
  });
};

const addEmergencyContact = (newContact) => {
  apiClient.post(`/api/contact/${contact.value.id}/emergency-contact`, {
    emergency_contact_id: newContact.id,
  });
  contact.value.emergency_contacts.push(newContact);
};

const deleteEmergencyContact = async (newContact) => {
  try {
    // Send the DELETE request
    await apiClient.delete(
      `/api/contact/${contact.value.id}/emergency-contact/${newContact.id}`,
    );

    // If successful, remove the contact from the emergency_contacts array
    const index = contact.value.emergency_contacts.findIndex(
      (c) => c.id === newContact.id,
    );
    if (index !== -1) {
      contact.value.emergency_contacts.splice(index, 1);
    }
  } catch (error) {
    console.error("Failed to delete emergency contact:", error);
  }

  const viewTicket = (ticketId) => {};
};
</script>
<template>
  <div>
    <form-box :header="contact?.name">
      <template v-slot:nav>
        <edit-button @click.prevent="edit" v-if="!editable"></edit-button>
        <save-button
          @click.prevent="save"
          title="Save"
          v-if="editable"
        ></save-button>
      </template>
    </form-box>

    <div class="grid grid-cols-1 lg:grid-cols-2 gap-6">
      <form-box header="Personal Details" v-if="contact?.id">
        <form
          class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 max-w-6xl"
        >
          <div>
            <label for="name">name</label>
            <TextField
              :data="contact.name"
              :editable="editable"
              @update="(val) => (contact.name = val)"
            />
          </div>
          <div>
            <label for="email">Email</label>
            <TextField
              :data="contact.email"
              :editable="editable"
              @update="(val) => (contact.email = val)"
            />
          </div>
          <div>
            <label for="phone">Phone</label>
            <TextField
              :data="contact.phone"
              :editable="editable"
              @update="(val) => (contact.phone = val)"
            />
          </div>
          <div>
            <label for="contact_address">Address</label>
            <textarea
              class="w-full"
              id="contact_address"
              v-model="contact.addr"
              :disabled="!editable"
            ></textarea>
          </div>
        </form>
      </form-box>

      <form-box header="Emergency Contacts">
        <SearchContact @selected="addEmergencyContact" />
        <TableList
          v-if="contact.emergency_contacts && contact.emergency_contacts.length"
          :modelValue="contact.emergency_contacts"
          :cols="[
            { name: 'Name', row: 'name', editable: true },
            { name: 'Email', row: 'email', editable: true },
            { name: 'Phone', row: 'phone', editable: true },
          ]"
          :deletable="true"
          :box="false"
          @delete="deleteEmergencyContact"
        />
      </form-box>
    </div>
    <br />
    <form-box header="Tickets">
      <table class="box list">
        <tbody>
          <tr v-for="ticket in tickets" :key="ticket">
            <td>{{ ticket.subject }}</td>
          </tr>
        </tbody>
      </table>
    </form-box>
  </div>
</template>

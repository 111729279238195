<script setup>
import ResponsiveList from '@/components/ResponsiveList.vue'
import TableList from '@/components/TableList.vue'
import ListSearch from '@/components/ListSearch.vue'
import PrevNextButtons from '@/components/PrevNextButtons.vue'
import dayjs from 'dayjs'
import { PlusCircleIcon } from '@heroicons/vue/24/solid'

import { defineProps } from 'vue'

const props = defineProps({
  searchUrl: { type: String, required: true },
  searchOutput: { type: Function, required: true },
  list: { type: Object, required: true },
  title: { type: Function, required: true },
  create: { type: String, required: false, default: null },
})

function view(val) {
  if (val?.id) props.list.view(val.id, props.title(val))
}
//
var advancedFormat = require('dayjs/plugin/advancedFormat')
dayjs.extend(advancedFormat)
</script>
<template>
  <div>
    <div class="box">
      <div class="flex w-full space-between">
        <ListSearch
          class="flex-1 mr-4"
          :url="props.searchUrl"
          :output="props.searchOutput"
          @selected="view"
        />
        <router-link
          :key="props.create"
          :to="props.create"
          class="btn accent"
          v-if="props.create"
          title="Create New"
        >
          <PlusCircleIcon v-show="create" class="icon" />
        </router-link>
      </div>
      <slot name="filters"></slot>
    </div>

    <div v-if="list.numberOfResults > 0">
      <TableList
        class=""
        :modelValue="props.list.data?.data"
        :cols="props.list.columns"
        :attributes="props.list.attributes"
        @view="view"
        @sort="props.list.sort;"
      />

      <div class="text-right">
        <PrevNextButtons
          :showPrev="props.list.showPrev"
          :showNext="props.list.showNext"
          @prev="props.list.prevPage"
          @next="props.list.nextPage"
          e
        />
      </div>
    </div>
    <div class="box" v-else>No data to display.</div>
  </div>
</template>

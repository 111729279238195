// App
import { createApp } from 'vue'
import { createPinia } from 'pinia'
import App from './App.vue'
import router from './router'
import { v4 as uuidv4 } from 'uuid'
import DatePicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'

// Import Helpers
import apiClient from './apiClient.js'
import dayjs from 'dayjs'

// Import Components
import ConfettiAnimation from '@/components/ConfettiAnimation.vue'
import CurrencyInput from '@/components/CurrencyInput'
import CurrencyOutput from '@/components/CurrencyOutput'
import DeleteButton from '@/components/DeleteButton'
import DownloadButton from '@/components/DownloadButton'
import EditButton from '@/components/EditButton'
import DateField from '@/components/DateField'
import FlagButton from '@/components/FlagButton'
import FormBox from '@/components/FormBox'
import ReferenceNumber from '@/components/ReferenceNumber'
import SaveButton from '@/components/SaveButton'
import SearchableInput from '@/components/SearchableInput'
import TextField from '@/components/TextField.vue'
import TimeField from '@/components/TimeField'

// Css
import '@/index.css'

// Helper for time
var relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(relativeTime)

// Setup app
const app = createApp(App)

// Global helpers
app.provide('apiClient', apiClient)
app.provide('dayjs', dayjs)
app.provide('uuidv4', uuidv4)

// Global components
app.component('ConfettiAnimation', ConfettiAnimation)
app.component('CurrencyInput', CurrencyInput)
app.component('CurrencyOutput', CurrencyOutput)
app.component('DatePicker', DatePicker)
app.component('DownloadButton', DownloadButton)
app.component('DateField', DateField)
app.component('DeleteButton', DeleteButton)
app.component('EditButton', EditButton)
app.component('FlagButton', FlagButton)
app.component('FormBox', FormBox)
app.component('ReferenceNumber', ReferenceNumber)
app.component('SaveButton', SaveButton)
app.component('SearchableInput', SearchableInput)
app.component('TextField', TextField)
app.component('TimeField', TimeField)

app.use(createPinia())
app.use(router)
app.mount('#app')

<script setup>
import { useListStore } from '@/stores/ListStore'
import dayjs from 'dayjs'
import TableListFull from '@/components/TableListFull.vue'

var relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(relativeTime)

const list = useListStore()

list.$reset()
list.configure({
  remote: '/api/customer',
  local: '/customer',
  columns: [
    { name: 'Company', row: 'company' },
    {
      name: 'Primary Contact',
      run: function (val) {
        return val.contact?.name?.substr(0, 30) ?? ''
      },
    },
    {
      name: 'Phone',
      run: function (val) {
        return val.contact?.phone?.substr(0, 30) ?? ''
      },
    },
    {
      name: 'Email',
      run: function (val) {
        return val.contact?.email?.substr(0, 30) ?? ''
      },
    },
    { name: 'Group', row: 'company' },
  ],
})

function view(ticket) {
  console.log('view')
  list.view(ticket.id, 'hello')
}
</script>
<template>
  <TableListFull
    searchUrl="/api/customer/search?query="
    :searchOutput="
      val => {
        return val.company + ' <' + val.contact?.name + '>'
      }
    "
    :list="list"
    :title="
      val => {
        return val.company ?? val.contact?.name
      }
    "
    create="/customer/create"
    @view="view"
  ></TableListFull>
</template>

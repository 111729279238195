<script setup>
import { ref } from 'vue'
import { useAuthStore } from '@/stores/AuthStore'
import { useRoute, useRouter } from 'vue-router'

let auth = useAuthStore()
let email = ref('')
let password = ref('')
let loginSuccessful = ref(true)
let loginError = ref(false)
const router = useRouter()

async function tryLogin() {
  if (email.value.length < 3 || password.value.length < 1) {
    return alert('Enter email and password')
  }

  loginSuccessful.value = await auth.tryLogin(email.value, password.value)
  if (loginSuccessful.value === false) {
    password.value = ''
    loginError.value = true
  }
  router.push({ name: 'calendar' })
}

function clearError() {
  loginError.value = false
}
</script>
<template>
  <section class="h-full bg-gray-100 dark:bg-gray-900">
    <div
      class="flex flex-col drop-shadow-xl items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0"
    >
      <div
        class="w-full bg-white rounded-xl shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700"
      >
        <img
          class="max-w-36 mx-auto m-2"
          src="/images/logo.png"
          alt="Medic 1"
        />
        <div class="p-6 space-y-4 md:space-y-6 sm:p-8">
          <h1
            class="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white"
          >
            Sign in to your account
          </h1>
          <form class="space-y-4 md:space-y-6" @submit.prevent="tryLogin">
            <div v-show="loginError" class="bg-red-500 text-white rounded p-2">
              Oops.. your login was incorrect
            </div>
            <div>
              <label
                for="email"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >Your email</label
              >
              <input
                type="email"
                name="email"
                id="email"
                v-model="email"
                class="drop-shadow bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="name@medic1.co.uk"
                required=""
                @change="clearError"
              />
            </div>
            <div>
              <label
                for="password"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >Password</label
              >
              <input
                type="password"
                name="password"
                id="password"
                v-model="password"
                placeholder="••••••••"
                class="drop-shadow bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                required=""
                @change="clearError"
              />
            </div>
            <button type="submit" class="btn">Sign in</button>
          </form>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { useListStore } from '@/stores/ListStore'
import { defineProps, defineEmits, onMounted, ref } from 'vue'
import Router from '@/router/index.js'

const props = defineProps({
  returnPath: { type: String, required: false },
  customer: { type: Object, required: false },
  resetAfterSelection: { type: Boolean, required: false, default: true },
})

const emit = defineEmits(['selected'])

const list = useListStore()
list.setRemote('/api/customer')

const useInitialCustomer = ref(props.customer?.company?.length)
onMounted(() => {
  list.searchQuery = props.customer?.company
})

const selected = function (val) {
  let copyOfVal = JSON.parse(JSON.stringify(val))
  emit('selected', copyOfVal)

  if (props.resetAfterSelection) list.searchClear()
  else {
    list.searchQuery = copyOfVal.company
    useInitialCustomer.value = true
  }
}

const addCustomerWindow = () => {
  Router.push({
    name: 'customer-create',
    query: {
      customerName: list.searchQuery,
      returnPath: props.returnPath ?? null,
    },
  })
}

const changeInput = () => {
  useInitialCustomer.value = false
  list.search()
  console.log('changing')
}
</script>
<template>
  <div>
    <input
      type="text"
      class="w-full"
      placeholder="Find or add a customer..."
      v-model="list.searchQuery"
      @input="changeInput"
    />
    <div
      v-if="list.searchQuery?.length && !useInitialCustomer"
      class="overflow-auto border border-t-0 w-full h-72 bg-white dark:dark:bg-slate-700 p-2"
    >
      <button
        class="hover:bg-sky-100 dark:hover:dark:bg-slate-800 p-2 rounded block w-full text-left"
        @click.prevent="addCustomerWindow"
      >
        + Add {{ list.searchQuery }}
      </button>
      <div
        v-for="r in list.searchResults"
        :key="r"
        class="hover:bg-gray-100 dark:hover:dark:bg-slate-800 p-2 rounded cursor-pointer"
        @click="() => selected(r)"
      >
        {{ r.company }}
      </div>
    </div>
  </div>
</template>

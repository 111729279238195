<script setup>
import { computed, ref, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import { useCalendarStore } from '@/stores/CalendarStore'
import CalendarHeader from '@/components/CalendarHeader.vue'
import dayjs from 'dayjs'

// Additional date formatting featur3es
var advancedFormat = require('dayjs/plugin/advancedFormat')
dayjs.extend(advancedFormat)

const calendarStore = useCalendarStore()
const route = useRoute()

onMounted(() => {
  calendarStore.updateDateFromRoute(route)
  calendarStore.fetchCalendarData()
})
// Map events to dates and track slots
const eventsByDate = computed(() => {
  const eventsMap = {}
  const slotMap = new Map()

  calendarStore.getDateRange.forEach(date => {
    var formatted = dayjs(date).format('YYYY-MM-DD')
    eventsMap[formatted] = []
  })

  let slotCounter = 0
  calendarStore.getEntries.forEach(event => {
    let slot
    if (slotMap.has(event.id)) {
      slot = slotMap.get(event.id)
    } else {
      slot = slotCounter++
      slotMap.set(event.id, slot)
    }

    event.dates.forEach(eventDate => {
      const dateStr = dayjs(eventDate.date).format('YYYY-MM-DD')
      if (eventsMap[dateStr]) {
        eventsMap[dateStr].push({ ...event, slot })
      }
    })
  })

  return eventsMap
})

const isToday = day => {
  return dayjs(day).isSame(dayjs(), 'day')
}
</script>

<template>
  <div class="grid grid-cols-7 grid-rows-[auto,repeat(6,minmax(0,1fr))] h-full">
    <CalendarHeader
      class="col-span-7 w-full min-h-0"
      view="month"
    ></CalendarHeader>
    <div
      v-for="day in calendarStore.getDateRange"
      :key="day"
      :class="[
        'border w-full h-full overflow-none',
        isToday(day) ? 'bg-neutral-100 dark:bg-slate-600' : '',
      ]"
    >
      <div class="text-right h-full">
        <small
          class="px-1"
          :class="[isToday(day) ? 'font-bold accent rounded' : '']"
          >{{ dayjs(day).format('Do') }}</small
        >
        <div
          v-for="event in eventsByDate[dayjs(day).format('YYYY-MM-DD')]"
          :key="event.id"
        >
          <router-link
            :to="'/reference/' + event.reference.id"
            class="text-left px-2 bg-green-400 block mb-0.5 dark:text-black truncate"
          >
            {{ event.reference.name }}
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

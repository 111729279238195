<script setup>
import { defineEmits, defineProps } from "vue";
import { ArrowLeftIcon, ArrowRightIcon } from "@heroicons/vue/24/solid";

defineProps({
  showPrev: Boolean,
  showNext: Boolean,
});

const emits = defineEmits(["prev", "next"]);
</script>
<template>
  <div>
    <button
      @click.prevent="emits('prev')"
      v-show="showPrev"
      class="btn accent"
      title="Previous Page"
    >
      <ArrowLeftIcon v-show="showPrev" class="icon" />
    </button>
    <div v-show="!showPrev" class="btn-spacer inline"></div>

    <button
      @click.prevent="emits('next')"
      v-show="showNext"
      class="btn accent"
      title="Next Page"
    >
      <ArrowRightIcon v-show="showNext" class="icon" />
    </button>
    <div v-show="!showNext" class="btn-spacer inline"></div>
  </div>
</template>

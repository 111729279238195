import { defineStore } from "pinia";
import ListService from "@/services/ListService";
import { ref } from "vue";

export const useListStoreNew = defineStore("listnew", {
  state: () => ({
    index: ref({}),
    searchQuery: "",
    listService: null,
    automaticRefresh: true,
  }),

  actions: {
    configure(config) {
      this.listService = new ListService(config.remote);
      const urlParams = new URLSearchParams(window.location.search);

      // Use any page number in url
      const page = urlParams.get("page") || 1;
      this.listService.setPage(page);

      // Use any filters in url
      const filters = urlParams.get("filters") || "";
      if (filters) {
        const filterPairs = filters.split("&");
        filterPairs.forEach((pair) => {
          const [key, value] = pair.split("=");
          if (key && value) {
            this.listService.addFilter(key, value);
          }
        });
      }
    },

    async fetchIndex() {
      try {
        const index = await this.listService.index();
        this.index.value = index;
      } catch (error) {
        console.error(error);
      }
    },

    empty() {
      this.index.value = [];
    },

    addFilter(key, value) {
      this.listService.addFilter(key, value);
    },

    clearFilters() {
      this.listService.clearFilters();
    },

    page(change) {
      this.listService.changePage(change);
      this.fetchIndex();
    },

    resetPage() {
      this.listService.setPage(1);
      this.fetchIndex();
    },
  },

  getters: {
    getIndex() {
      return this.index.value?.data || [];
    },

    getPage() {
      return this.listService.getPage();
    },

    getFilters() {
      return this.listService.getFilters();
    },

    showPrev() {
      return Boolean(this.index.value?.prev_page_url);
    },

    showNext() {
      return Boolean(this.index.value?.next_page_url);
    },
  },
});

<script setup>
import apiClient from '@/apiClient.js'
import ResponsiveListFull from '@/components/ResponsiveListFull'
import { useListStore } from '@/stores/ListStore'

const list = useListStore()
list.$reset()
list.configure({
  remote: '/api/reference',
  local: '/reference',
  columns: [
    {
      name: 'Reference',
      run: function (val) {
        return val.txt
      },
    },
    {
      name: 'Type',
      run: function (val) {
        return val.type.name
      },
    },
    {
      name: 'Description',
      run: function (val) {
        return val.name
      },
      class: 'lg:col-span-3',
    },
  ],
  attributes: {
    highlightRow: val => {},
  },
})
</script>
<template>
  <div>
    <ResponsiveListFull
      searchUrl="/api/reference/search?query="
      :searchOutput="
        val => {
          return val.txt + ' - ' + val.name
        }
      "
      :list="list"
      :title="
        val => {
          val
        }
      "
      create="/reference/create"
    >
    </ResponsiveListFull>
  </div>
</template>

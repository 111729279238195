<script setup>
import ResponsiveList from '@/components/ResponsiveList.vue'
import ListSearch from '@/components/ListSearch.vue'
import PrevNextButtons from '@/components/PrevNextButtons.vue'
import dayjs from 'dayjs'
import { PlusCircleIcon } from '@heroicons/vue/24/solid'

import { defineProps } from 'vue'

const props = defineProps({
  searchUrl: { type: String, required: true },
  searchOutput: { type: Function, required: true },
  list: { type: Object, required: true },
  title: { type: Function, required: true },
  create: { type: String, required: false, default: null },
  width: { type: Number, required: false },
})

function view(val) {
  console.log('view')
  if (val?.id) props.list.view(val.id, props.title(val))
}
//
var advancedFormat = require('dayjs/plugin/advancedFormat')
dayjs.extend(advancedFormat)
</script>
<template>
  <div>
    <div class="flex w-full space-between box">
      <ListSearch
        class="flex-1 mr-4"
        :url="props.searchUrl"
        :output="props.searchOutput"
        @selected="view"
      />
      <router-link
        :key="props.create"
        :to="props.create"
        class="btn accent"
        v-if="props.create"
        title="Create New"
      >
        <PlusCircleIcon v-show="create" class="icon" />
      </router-link>
      <slot name="controls"></slot>
    </div>

    <ResponsiveList
      v-if="props.list.data.data"
      :data="props.list.data?.data"
      :cols="props.list.columns"
      :width="props.width"
      @view="view"
    />

    <div class="text-right">
      <PrevNextButtons
        :showPrev="props.list.showPrev"
        :showNext="props.list.showNext"
        @prev="props.list.prevPage"
        @next="props.list.nextPage"
      />
    </div>
  </div>
</template>

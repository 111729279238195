<script setup>
import { useListStore } from '@/stores/ListStore'
import ResponsiveListFull from '@/components/ResponsiveListFull.vue'
import * as Icon from '@heroicons/vue/24/solid'

const list = useListStore()
list.$reset()
list.configure({
  remote: '/api/staff',
  local: '/staff',
  columns: [
    {
      name: 'Name',
      run: function (val) {
        return val.contact.name
      },
    },
    {
      name: 'Email',
      run: function (val) {
        return val.contact.email
      },
    },
    {
      name: 'Phone',
      run: function (val) {
        return val.contact.phone
      },
    },
  ],
  attributes: {
    highlightRow: val => {
      return val.awaitingResponse === 1 ? 1 : 0
    },
  },
})
</script>
<template>
  <div>
    <ResponsiveListFull
      searchUrl="/api/staff/search?query="
      :searchOutput="
        val => {
          return val.contact.name + ' <' + val.contact?.email + '>'
        }
      "
      :list="list"
      :title="
        val => {
          val
        }
      "
      create="/staff/create"
    >
      <template v-slot:controls>
        <router-link to="/staff/export">
          <button class="btn save">
            <Icon.CloudArrowDownIcon class="icon" />
          </button>
        </router-link>
      </template>
    </ResponsiveListFull>
  </div>
</template>

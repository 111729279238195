<script setup>
import { useListStore } from '@/stores/ListStore'
import dayjs from 'dayjs'
import TableListFull from '@/components/TableListFull.vue'
import FormBox from '@/components/FormBox.vue'

var advancedFormat = require('dayjs/plugin/advancedFormat')
dayjs.extend(advancedFormat)

let list = useListStore()
list.$reset()
list.configure({
  remote: '/api/invoice',
  local: '/invoice',
  columns: [
    {
      name: 'Reference',
      sortBy: 'reference.id',
      run: function (val) {
        return val?.reference?.txt
      },
      class: 'reference',
    },
    {
      name: 'Customer',
      run: function (val) {
        return val.reference?.customer?.company?.length > 30
          ? val.reference.customer.company.substr(0, 30) + '...'
          : val.reference?.customer?.company
      },
    },
    {
      name: 'Description',
      run: function (val) {
        return val.reference?.name.length > 30
          ? val.reference.name.substr(0, 30) + '...'
          : val.reference?.name
      },
    },
    {
      name: 'Amount',
      run: val => val.subtotal,
      type: 'currency',
    },
    {
      name: 'VAT',
      run: val => val.vat,
      type: 'currency',
    },
    {
      name: 'Total',
      run: val => val.subtotal + val.vat,
      type: 'currency',
    },
    {
      name: 'Payment',
      run: function (val) {
        return val.paid_at ? dayjs(val.paid_at).format('Do MMM YY') : 'Unpaid'
      },
      class: 'text-right',
    },
    {
      name: 'Updated',
      run: function (val) {
        return dayjs(val.reference.updated_at).format('Do MMM YY')
      },
      class: 'text-right',
    },
  ],
})
</script>
<template>
  <div>
    <TableListFull
      searchUrl="/api/invoice/search?query="
      :searchOutput="
        val => {
          return (
            val.reference.txt +
            ' - ' +
            val.reference.name +
            ' <' +
            (val.reference?.customer?.company ??
              val.reference?.customer?.contact?.name) +
            '>'
          )
        }
      "
      :list="list"
      :title="
        val => {
          val
        }
      "
      create="/invoice/create"
    >
      <template v-slot:filters>
        <div class="grid grid-cols-1 md:grid-cols-4 mt-4">
          <button
            @click.prevent="
              () => {
                list.clearFilters()
                list.load()
              }
            "
            class="btn-plain bg-sky-500"
          >
            All
          </button>
          <button
            class="btn-plain bg-indigo-500"
            @click.prevent="
              () => {
                list.clearFilters()
                list.addFilter('sent_at', '0')
                list.load()
              }
            "
          >
            Unsent
          </button>
          <button
            class="btn-plain bg-rose-500"
            @click.prevent="
              () => {
                list.clearFilters()
                list.addFilter('paid_at', '0')
                list.addFilter('sent_at', '1')
                list.load()
              }
            "
          >
            Unpaid
          </button>
          <button
            class="btn-plain bg-teal-500"
            @click.prevent="
              () => {
                list.clearFilters()
                list.addFilter('paid_at', '1')
                list.load()
              }
            "
          >
            Paid
          </button>
        </div>
      </template>
    </TableListFull>
  </div>
</template>

<script setup>
import { defineProps, defineEmits, computed, ref } from 'vue'

const props = defineProps({
  cols: { type: Array, required: true },
  data: { type: Array, required: true },
  width: { type: Number, default: 1 },
})

const emit = defineEmits(['view'])

const getValue = (col, val) => (col.run ? col.run(val) : val[col.row])
</script>
<template>
  <div class="box">
    <header
      class="grid grid-cols-1 md:grid-cols-[repeat(auto-fit,minmax(0,1fr))]"
    >
      <div
        v-for="col in cols"
        :key="col.name"
        :class="['hidden lg:flex', col.class]"
        :style="`flex: ${col.width ?? props.defaultWidth}`"
      >
        {{ col.name }}
      </div>
    </header>
    <div>
      <template v-for="line in data" :key="line.id">
        <div
          class="grid grid-cols-1 lg:grid-cols-[repeat(auto-fit,minmax(0,1fr))] border-b border-gray-300 hover:bg-gray-100 dark:hover:bg-gray-900 cursor-pointer mb-10 lg:mb-0"
          @click.prevent="emit('view', line)"
        >
          <div
            v-for="col in cols"
            :key="col.name"
            class="py-3 cursor-pointer"
            :class="col.class"
            :style="`flex: ${col.width ?? props.defaultWidth}`"
          >
            <div class="lg:hidden font-bold">{{ col.name }}</div>
            <!-- Show column name on mobile -->
            {{ getValue(col, line) }}
          </div>
        </div>
      </template>
    </div>
  </div>
</template>
